// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-custom-pages-register-index-js": () => import("./../../src/customPages/register/index.js" /* webpackChunkName: "component---src-custom-pages-register-index-js" */),
  "component---src-custom-pages-register-thankyou-js": () => import("./../../src/customPages/register/thankyou.js" /* webpackChunkName: "component---src-custom-pages-register-thankyou-js" */),
  "component---src-pages-app-js": () => import("./../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-templates-info-js": () => import("./../../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */)
}

