import axios from 'axios';
import { isAuthenticated } from './auth'
import { isClient } from '../styles/utils'

//const SERVER_URL = 'http://aitf-back.test/wp-json/'
const SERVER_URL = 'https://admin.adventuresinthefield.com/wp-json/'
//const SERVER_URL = 'https://admin-staging.adventuresinthefield.com/wp-json/'


export function fetchData(endpoint) {    

    let config = {}

    if (isClient()) {
        const jwt = localStorage.getItem('jwtToken');

        if (jwt) {
            config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json'
                }
            }
        }
    }
    return new Promise((resolve, reject) => {
        axios.get(SERVER_URL + endpoint, config)
            .then((response) => {
                if (typeof response.data !== null) {
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                reject(error)
            })      
    
    })
}


export function postData(endpoint, data) {

    // console.log(SERVER_URL, endpoint, data)

    let config = {}

    if (isClient()) {
        const jwt = localStorage.getItem('jwtToken');

        if (jwt) {
            config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json'
                }
            }      
        }
    }

    return new Promise((resolve, reject) => {

        axios.post(SERVER_URL + endpoint, data, config)
            .then((response) => {
                console.log('response',response);
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                console.log('error',error);
                reject(error)
            })   
    })
}


export function checkToken() {    

    let config = {}

    if (isClient()) {
        const jwt = localStorage.getItem('jwtToken');

        if (jwt) {
            config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json'
                }
            }
        }
    }
        
    return new Promise((resolve, reject) => {
        axios.post(SERVER_URL + 'wpapi/checkToken', {}, config)
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                reject(error)
            })      
    
    })
}