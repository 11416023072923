import { postData } from "./api";
import { isClient } from "../styles/utils";
import { navigate } from "gatsby";

export const handleLogin = (cred) => {
    return new Promise((resolve, reject) => {
        postData('pda/login', cred)
            .then(response => {
                console.log(response.sections_visited)

                if (response.error) {
                    resolve(response)
                } else {
                    postData('simple-jwt-authentication/v1/token', cred)
                        .then(response => {
                            console.log(response)
                            if (response.token) {
                                var dateNow = new Date();

                                localStorage.setItem('jwtToken', response.token)
                                localStorage.setItem('jwtExpiry', response.token_expires)
                                localStorage.setItem('userId', response.user_id)

                                resolve(response.token);
                            } else {
                                // Display error message
                                resolve(response);
                            }
                        })
                }
            })
            .catch(error =>  {
                console.log(error)
                reject(error)
            })   
    })   

    return false
}

export const handleRegisterPayment = (data) => {
    return new Promise((resolve, reject) => {
        postData('pda/register', data)
            .then(response => {
                console.log('register response',response);
                resolve(response);
            })
            .catch(error =>  {
                console.log('error',error)
                reject(error)
            })   
    })   

    return false
}



export function isAuthenticated() {
    if (!isClient()) return false // return true on server

    // let auth = localStorage.getItem('auth');

    // if (auth) {
    //     return true
    // }

    let jwtToken = localStorage.getItem('jwtToken');
    let jwtExpiry = localStorage.getItem('jwtExpiry');
    var dateNow = Math.round(+ new Date()/1000);

    if (jwtToken && jwtExpiry && (parseInt(jwtExpiry) > dateNow)) {
        return true
    } else {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtExpiry');
        
        return false
    }
}



export function logout() {
    localStorage.removeItem('auth');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtExpiry');
    delete window.localStorage.primingSectionsVisited
    delete window.localStorage.fieldGalleriesVisited

    navigate('/')
}
