import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { grey, orange } from './colors';

const maxWidth = 1280;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        overflow-x: hidden;

        ${media.phone`
            overflow: visible;
        `}
    }
    
    a {
        text-decoration: none;
    }

    a, p, div {
        color: black;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    p:first-child {
		margin-top: 0;
	}
`;

export const container = css`
    display: flex;
	height: 100%;
	width: 100%;
    max-width: ${maxWidth}px;
    margin: 0 auto;
`

export const hoverState = css`
    transition: 0.3s opacity ease;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 24px; 
    padding-right: 24px;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}

    ${media.phone`
        padding-left: 24px; 
        padding-right: 24px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const videoEmbed = css`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export const input = css`
    width: 280px;
    height: 40px;
    border-radius: 4px;
`

export const button = css`
    height: 40px;
    border-radius: 8px;
    color: white;
    background: ${orange};

    font-size: 19px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`