import React, { Component } from 'react'
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'

import { bgIcon, hoverState } from '../../styles/global'

import playIcon from '../../assets/images/audio-mute-on.png'
import pauseIcon from '../../assets/images/audio-mute-off.png'
import silence from '../../assets/audio/silence.mp3'

import { getSettings, fetchSettings, updateSettings } from '../../store/settings';
import { isClient, media, width } from '../../styles/utils';
import { isAuthenticated } from "../../services/auth"
import { darkGrey, grey } from '../../styles/colors';

let interval;

class AudioPlayer extends Component {

    state = {
        controls: true,
        url: '',
        playing: true,
        played: 0,
        lastIntent: 0,
        loaded: 0,
        duration: 0,
        seeking: false,
        muted: false,
        loop: true,
        volume: 0
    }

    componentWillMount = () => {
        this.props.fetchSettings();

        // testing - turn off
        // this.togglePlayPause()
    }

    componentDidMount() {
        this.props.fetchSettings();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { settings } = this.props;
        const { fading } = this.state;

        // Detect audio URL change
        
        if (settings.audio !== prevProps.settings.audio) {
            if (settings.audio !== this.state.url) {
                const audio = settings.audio;

                // Update w/ new audio

                if (audio) {
                    clearInterval(interval)
                    this.updateAudio(audio)
                } 

                // Fade out if no audio
                
                if (!audio) {
                    clearInterval(interval)
                    this.fadeVolume(this.state.volume, 0, 2, () => {
                        this.updateAudio('')
                    })
                } 
            }
        }
    }

    updateAudio = (audio) => {
        this.fadeVolume(this.state.volume, 1, 5);
        this.setState({
            url: audio,
            fading: true
        })
    }

    setVolume = volume => {
        this.setState({ volume: volume})
    }

    fadeVolume = (current, target, seconds, callback) => {
        const fadeInterval = 100
        const steps = seconds * 1000 / fadeInterval
        const step = (target - current) / steps

        let volume = current
        let count = 0
  
        interval = setInterval(() => {
            count++

            // clear interval if target volume reached

            if (count >= steps) {
                this.setVolume(target)  
                this.setState({
                    fading: false
                })
                clearInterval(interval)
                callback && callback()
            }

            volume = Math.min(Math.max(volume += step, 0), 1); // normalize volume
            this.setVolume(volume)  
        }, fadeInterval)
    }

    togglePlayPause = () => {
        const { settings, updateSettings } = this.props;

        console.log(settings)

        if (width.phone() && !settings.mobileAudioActive) {
            updateSettings({
                mobileAudioActive: true
            })

            this.setState({playing: false}, () => {
                this.setState({ playing: true })
            })
         
        } else {
            this.setState({ playing: !this.state.playing })
        }
    }

    onURLChange = () => {
        console.log(window.location)
    }

    render() {       
        const { settings } = this.props;
        const { url } = this.state;
        
              
        // if (!isAuthenticated()) { 
        //     return <></> 
        // };

        return (
           <>
                <Wrapper
                    home={isClient() && location.pathname == ('/')}
                    mobileVisible={url}
                >
                    <Silence 
                        src={silence} 
                        allow="autoplay" 
                        id="audio"
                    />

                    <Audio 
                        ref={this.ref}
                        url={this.state.url}
                        controls={this.state.controls}
                        muted={this.state.muted}
                        playing={this.state.playing}
                        loop={this.state.loop}
                        volume={this.state.volume}
                        width={'0'}
                        height={'0'}
                        onProgress={this.onProgress}
                        onDuration={this.onDuration}
                        //onReady={this.onReady}
                    />

                    <PlayPause
                        onClick={()=> this.togglePlayPause()}
                    >
                
                        {!width.phone() && url && (
                            <>
                                {!this.state.playing && ( 
                                    <PlayIcon/> 
                                )}
                                
                                {this.state.playing && ( 
                                    <PauseIcon/> 
                                )}
                            </>
                        )}

                        {width.phone() && settings && url && (
                            <>
                                {settings.mobileAudioActive && (
                                    <>
                                        {!this.state.playing && ( 
                                            <PlayIcon/> 
                                        )}
                                        
                                        {this.state.playing && ( 
                                            <PauseIcon/> 
                                        )}
                                    </>
                                )}

                                {!settings.mobileAudioActive && (
                                    <ActiveAudioIcon/> 
                                )}
                            </>
                        )}

                    </PlayPause>
                </Wrapper>

                {!settings.mobileAudioActive && url && (
                    <ActiveAudioLabel>
                        Tap play to activate audio
                    </ActiveAudioLabel> 
                )}
           </>
        )
    }
}


const Wrapper = styled.div`
	position: absolute;
	top: 331px;
	right: 34px;
    z-index: 10;

    ${props => {
        if (props.home) return css`
            top: 24px;
        `
    }}

    ${media.phone`
        top: auto;
        bottom: 38px;
        right: 17px;
        width: 40px;
        height: 40px;
        background: rgba(255,255,255, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;

        background: white;
        position: fixed;

        display: none;

        ${props => {
            if (props.mobileVisible) return css`
                display: flex;
            `
        }}
    `}
`

const Silence = styled.iframe`
    display: none;
`

const Audio = styled(ReactPlayer)`
    visibility: hidden;
`

const PlayPause = styled.div`
    position: relative;

    &:hover {
        cursor: pointer;
    }
`

const PlayIcon = styled.div`
    background-image: url(${playIcon});
	${bgIcon}
    width: 28px;
    height: 27px;
`

const PauseIcon = styled.div`
    background-image: url(${pauseIcon});
	${bgIcon}
    width: 28px;
    height: 27px;
`

const ActiveAudioIcon = styled.div`
    background-image: url(${require('../../assets/images/audio-activate.png')});
	${bgIcon}
    width: 28px;
    height: 27px;
`

const ActiveAudioLabel = styled.div`
    display: none;

    ${media.phone`
        display: flex;
        bottom: 50px;
        right: 64px;
        position: fixed;
    `}

    font-family: 'LatoWebBold';
    font-size: 11px;
    letter-spacing: 1.9px;
    border-radius: 8px;
    margin-top: 14px;
    color: ${grey};
    
    text-transform: uppercase;
    z-index: 10;
`



const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);