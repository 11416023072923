import React from "react"
import { Provider } from "react-redux"
import store from './src/store/configureStore';
import { PortalProvider } from "react-portal-hook";

export default ({ element }) => {
    return (
        <Provider 
            store={store}
        >
            <PortalProvider>
                {element}
            </PortalProvider>
        </Provider>
    )
}