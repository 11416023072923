import React from "react"
import wrapWithProvider from "./wrap-with-provider"
import AudioPlayer from "./src/components/AudioPlayer/AudioPlayer";
import { Location } from "@reach/router";

import "./src/styles/fonts.css"

export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element, props }) => {
    return (
        <>
            {element}
            <AudioPlayer
                {...props}
            />      
        </>
    )
}

export const onRouteUpdate = ({ location }) => {
    // if (typeof fbq === `function`) {
    //     fbq('track', 'ViewContent');
    // }
}